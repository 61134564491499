import Vue from 'vue'
import VueRouter from 'vue-router'

/*get access to the store*/
import store from '@/store';

Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'Home',
    component: () => import('../views/home/show')
},
{
    path: '/intro',
    name: 'Intro',
    component: () => import('../views/intro/show')
},
{
    path: '/touren',
    name: 'KulTouren',
    component: () => import('../views/touren/index.vue')
},
{
  path: '/touren/:id',
  name: 'Kulturspur',
  component: () => import('../views/touren/show.vue')
},
{
    path: '/destinations/:target',
    name: 'Destinations',
    component: () => import('../views/destination/index')
},
{
    path: '/location/karte/:destination',
    name: 'LocationKarte',
    component: () => import('../views/location/map')
},
{
    path: '/locations/:destination',
    name: 'LocationIndex',
    component: () => import('../views/location/index')
},
{
    path: '/location/:id',
    name: 'LocationView',
    component: () => import('../views/location/view')
},
{
    path: '/events/:destination',
    name: 'EventIndex',
    component: () => import('../views/event/index')
},
{
    path: '/unterkuenfte',
    name: 'Unterkünfte',
    component: () => import('../views/unterkuenfte/index')
},
{
    path: '/event/:id',
    name: 'EventView',
    component: () => import('../views/event/view')
},
{
    path: '/trips',
    name: 'Trips',
    component: () => import('../views/reiseplan/index')
},
{
    path: '/trip/karte',
    name: 'TripKarte',
    component: () => import('../views/reiseplan/map')
},
{
    path: '/trip/view/:id',
    name: 'TripView',
    component: () => import('../views/reiseplan/view')
},
{
    path: '/trip/edit/:id',
    name: 'TripEdit',
    component: () => import('../views/reiseplan/edit'),
    meta: {
        auth: true
    }
},
{
    path: '/login',
    name: 'Login',
    component: () => import('../views/auth/login'),
    meta: {
        auth: false
    }
},
{
    path: '/signup',
    name: 'Signup',
    component: () => import('../views/auth/register'),
    meta: {
        auth: false
    }
},
{
    path: '/search',
    name: 'Search',
    component: () => import('../views/search/index'),
},
{
    path: '/apply-for-new-password',
    name: 'applyForNewPassword',
    component: () => import('../views/auth/applyForNewPassword'),
    meta: {
        auth: false
    }
},
{
    path: '/password-reset/:token',
    name: 'passwordForgotten',
    component: () => import('../views/auth/passwordForgotten'),
    meta: {
        auth: false
    }
},
{
    path: '/verify/:token',
    name: 'Verify',
    component: () => import('../views/auth/login')
},
{
    path: '/change-password',
    name: 'ChangePassword',
    component: () => import('../views/auth/changePassword')
},
{
    path: '/password-changed',
    name: 'PasswordChanged',
    component: () => import('../views/auth/passwordChanged')
},
{
    path: '/profile',
    name: 'Profile',
    component: () => import('../views/profile/view'),
    meta: {
        auth: true
    }
},
{
    path: '/edit-profile',
    name: 'EditProfile',
    component: () => import('../views/profile/edit'),
    meta: {
        auth: true
    }
},
{
    path: '/mehr',
    name: 'Mehr',
    component: () => import('../views/mehr/view.vue'),
},
{
    path: '/impressum',
    name: 'Impressum',
    component: () => import('@/views/system/show.vue'),
},
{
    path: '/datenschutz',
    name: 'Datenschutz',
    component: () => import('@/views/system/show.vue'),
},
{
    path: '/nutzungsbedingungen',
    name: 'Nutzungsbedingungen',
    component: () => import('@/views/system/nutzungsbedingungen.vue'),
},
{
    path: '/system/:id',
    name: 'SystemView',
    component: () => import('@/views/system/show.vue'),
},
{
    path: '*',
    name: '404',
    component: () => import('@/views/errors/404.vue'),
}
]

const router = new VueRouter({
    mode: 'history',
    routes
});

router.beforeEach((to, from, next) => {
    setTimeout(() => { window.scrollTo(0, 0); }, 100);

    var loginTime = store.getters.getLoginTime;
    if (loginTime != null) {
        var currentTime = Date.now();
        var timeDifferenceInSecond = Math.ceil(Math.abs(currentTime - loginTime) / 1000);
        var timeDifferenceInMinutes = timeDifferenceInSecond / 60;
        if (timeDifferenceInMinutes > 60) {
            /*logout*/
            store.dispatch('logout');
        }
        console.log(timeDifferenceInMinutes);
    }


    const token = store.getters['getToken'];
    if (to.meta.auth == true && token === null) {
        /*go to login - only users that are logged in are allowed*/
        next('/login');
        return;
    } else if (to.meta.auth == false && token != null) {
        /*go home - only not logged in users are allowed*/
        next('/');
        return;
    }

    setTimeout(() => {     next();
}, 200);

});

export default router