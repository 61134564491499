import Vue from 'vue'
import Vuex from 'vuex'
import App from './App.vue'
import router from './router'
import VueAxios from 'vue-axios'
import store from './store';
import axios from 'axios';
import VueScrollTo from 'vue-scrollto';
import Loading from 'vue-loading-overlay';
import VueRouterBackButton from 'vue-router-back-button'
import VueClipboard from 'vue-clipboard2'
import Notifications from 'vue-notification'
import VueMatomo from 'vue-matomo'

Vue.config.productionTip = false

Vue.use(VueScrollTo);
Vue.use(VueClipboard);
Vue.use(Vuex);
Vue.use(VueRouterBackButton, { router });
Vue.use(VueAxios, axios);
Vue.use(VueScrollTo);
Vue.use(Notifications);

Vue.use(VueMatomo, {
  host: "https://matomo.software-by-design.de/",
  siteId: 3,
  trackerFileName: 'matomo',
  router: router,
  enableLinkTracking: true,
  requireConsent: false,
  trackInitialView: true,
  disableCookies: false,
  enableHeartBeatTimer: false,
  heartBeatTimerInterval: 15,
  debug: false,
  userId: undefined,
  cookieDomain: undefined,
  domains: undefined,
  preInitActions: []
});

/*config*/
/*id of the current app*/
Vue.prototype.$appId= '1';
Vue.prototype.$institutionId= '1';
Vue.prototype.$offerId= '2';
Vue.prototype.$poiId = '12';
Vue.prototype.$kultourId = '13';
Vue.prototype.$backendUrl = "https://cms.tripmeister.de";
Vue.prototype.$backendApiUrl = "https://cms.tripmeister.de/api/";
Vue.prototype.$impressumContentId = "17373";
Vue.prototype.$datenschutzContentId = "17374";
Vue.prototype.$informationspflichtenId = "17559";
Vue.prototype.$radius = 200000;

import '@/scss/style.scss';
import 'vue-loading-overlay/dist/vue-loading.css';
import 'material-icons/iconfont/material-icons.css';


/*Initialisieren von Loading*/
Vue.use(Loading, {
  color: 'rgb(148, 192, 31)',
  loader: 'dots',
  width: 140,
  FullPage: true
});

export const bus = new Vue({})

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
